import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from './components/Layout/Layout';
import LoginPage from './pages/LoginPage';
import GalleryPage from './pages/GalleryPage';
import UserGalleryPage from './pages/UserGalleryPage';
import ImagePage from './pages/ImagePage';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import ReviewPage from './components/Review/ReviewPage';
import { AuthInitializer } from './components/AuthInitializer';

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <>
      <AuthInitializer />
      <Router>
        <Routes>
          <Route
            path="/login"
            element={
              isAuthenticated ? <Navigate to="/review" replace /> : <LoginPage />
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }
          >
            <Route path="/review" element={<ReviewPage />} />
            <Route index element={<Navigate to="/gallery" replace />} />
            <Route path="gallery" element={<GalleryPage />} />
            <Route path="user-gallery" element={<UserGalleryPage />} />
            <Route path="image/:id" element={<ImagePage />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App; 