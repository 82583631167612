import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config';
import { setCredentials, logout as logoutAction } from '../features/auth/authSlice';
import jwt_decode from 'jwt-decode';

// Add helper functions to handle token persistence
const loadToken = () => localStorage.getItem('token');
const saveToken = (token) => localStorage.setItem('token', token);
const removeToken = () => localStorage.removeItem('token');

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      // Try getting token from state first, then fallback to localStorage
      const token = getState().auth.token || loadToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/token/pair',
        method: 'POST',
        body: credentials,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // Save token to localStorage
          saveToken(data.access);
          
          // Get username from credentials or decode from token
          const username = args.username; // Use the username from login credentials
          
          dispatch(setCredentials({ 
            token: data.access,
            username: username
          }));
        } catch (error) {
          console.error('Login failed:', error);
        }
      },
    }),
    getImages: builder.query({
      query: (page = 1) => `/v1/viewer/images?page=${page}&page_size=12`,
      // Merge results for infinite scroll
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        if (!currentCache) return newItems;
        return [...currentCache, ...newItems];
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: 0,
    }),
    getImagesStatusedByUser: builder.query({
      query: (page = 1) => ({
        url: `/v1/viewer/images_list/user-statused?page=${page}&page_size=12`,
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      }),
      // Merge results for infinite scroll
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        if (!currentCache) return newItems;
        return [...currentCache, ...newItems];
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: 0,
    }),
    getImage: builder.query({
      query: (id) => `/v1/viewer/image/${id}`,
    }),
    getImagePending: builder.query({
      query: (id) => `/v1/viewer/image/last-pending`,
      keepUnusedDataFor: 0,
    }),
    rateImage: builder.mutation({
      query: ({ id, rating }) => ({
        url: `/v1/viewer/image/${id}/rate`,
        method: 'PATCH',
        body: { rating },
      }),
    }),
    statusImage: builder.mutation({
      query: ({ id, status }) => ({
        url: `/v1/viewer/image/${id}/status`,
        method: 'PATCH',
        body: { status },
      }),
    }),
  }),
});

// Rename the helper function to logoutHelper
export const logoutHelper = (dispatch) => {
  removeToken(); // Use the existing removeToken helper
  dispatch(logoutAction());
};

export const {
  useLoginMutation,
  useGetImagesQuery,
  useGetImageQuery,
  useGetImagePendingQuery,
  useRateImageMutation,
  useStatusImageMutation,
  useGetImagesStatusedByUserQuery,
} = api;
