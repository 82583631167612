import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../features/auth/authSlice';
import { Button, Layout, Space, Typography } from 'antd';

const { Header: AntHeader, Text } = Layout;

const Header = () => {
  const dispatch = useDispatch();
  const username = useSelector((state) => state?.auth?.username);

  useEffect(() => {
    console.log(username);
  }, [username]);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <AntHeader style={{ 
      width: '100%', 
      background: '#fff',
      borderBottom: '1px solid #f0f0f0',
      padding: '0 24px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      <Space>
        <Link
          to="/gallery"
          style={{
            fontSize: '14px',
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.88)',
          }}
        >
          Gallery
        </Link>
        <Link
          to="/user-gallery"
          style={{
            fontSize: '14px',
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.88)',
          }}
        >
          My Gallery
        </Link>
        <Link
          to="/review"
          style={{
            fontSize: '14px',
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.88)',
          }}
        >
          Review Image
        </Link>
      </Space>
      <Space>
        <Typography.Text>
          {username}
        </Typography.Text>
        <Button 
          onClick={handleLogout}
          danger
          size="small"
        >
          Logout
        </Button>
      </Space>
    </AntHeader>
  );
};

export default Header;
